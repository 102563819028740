/* Cards */

.card {
  @apply 
    bg-white
    overflow-hidden
    pt-[56.25%]
    relative
    rounded-2xl
  ;

  &__content {
    @apply
      absolute
      bottom-0
      flex
      gap-4
      items-center
      left-0
      p-4
      right-0
      z-10
    ;
  }

  &__link {
    @apply
      absolute
      bottom-0
      left-0
      right-0
      top-0
    ;
  }

  &__image {
    @apply
      absolute
      bottom-0
      h-full
      left-0
      object-cover
      right-0
      top-0
      w-full
    ;
  }

  &__shadow {
    @apply
      absolute
      bg-gradient-to-t
      bottom-0
      from-gray-900
      left-0
      right-0
      top-0
      z-10
    ;
  }

  &--with-image {
    @apply text-white;
  }
}

.card__title {
  @apply font-semibold overflow-hidden text-left max-w-[50ch] break-words;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;

  &--multiline {
    -webkit-line-clamp: 2;
  }

  &:last-child {
    @apply m-0;
  }
}

.card__meta {
  @apply text-sm opacity-70 overflow-hidden max-w-[50ch];
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.card__logo {
  @apply flex items-center justify-center rounded-full overflow-hidden h-[72px] w-[72px];

  @media screen and (max-width: 330px) {
    @apply h-[44px] w-[44px];
  }
}