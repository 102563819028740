.icon {
  @apply
    h-6
    w-6
  ;

  &--mini {
    @apply w-4;
  }

  &--small {
    @apply w-5;
  }

  &:not(.inline-fill) {
    @apply fill-current;
  }

  .hide {
    @apply opacity-0 invisible;
  }

  .active & .show {
    @apply opacity-0 invisible;
  }

  .active & .hide {
    @apply opacity-100 visible;
  }
}