.page__text-content {
  h3 {
    @apply
      font-bold
      mb-2
      text-3xl
      lg:leading-tight
    ;
  }
  a {
    @apply text-mixlr-red;
  }
  h4 {
    @apply font-bold mb-2 text-xl;
  }
  p {
    @apply mb-4;
  }
  ol {
    @apply list-[upper-roman] mb-4 ml-12;
  }
  ol li {
    @apply mb-2;
  }
}