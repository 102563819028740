@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'swiper/swiper-bundle.min.css';
@import 'styles/components/buttons.scss';
@import 'styles/components/cards.scss';
@import 'styles/components/pills.scss';
@import 'styles/components/media.scss';
@import 'styles/components/icons.scss';
@import 'styles/components/action_button.scss';
@import 'styles/components/detail_toggle.scss';
@import 'styles/components/forms.scss';
@import 'styles/components/settings.scss';
@import 'styles/components/pages.scss';
@import 'styles/components/pagination.scss';

/* Overides */

.swiper-pagination {
  position: static;

  --swiper-pagination-color: #ed1c24;
  --swiper-theme-color: #f3f3f3;
}

/* Utility classes */

.blockquote {
  @apply mb-4 text-xl lg:text-2xl after:content-['”'] after:text-mixlr-red after:absolute after:-bottom-24 after:-right-4 after:text-9xl before:content-['“'] before:text-mixlr-red before:absolute before:-top-6 before:-left-4 before:text-9xl;
}

/* Custom styles */

.number-list {
  counter-reset: li;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.number-list li {
  display: flex;
  margin-bottom: 12px;
}

.number-list li:before{
  align-items: center;
  background-color: #ed1c24;
  border-radius: 15px;
  color: #fff;
  content: counter(li);
  counter-increment: li;
  display: flex;
  flex: none;
  font-weight: bold;
  height: 30px;
  margin-right: 12px;
  position: relative;
  justify-content: center;
  top: -4px;
  width: 30px;
}

/* Tooltip */

.tooltip {
  @apply bg-white hidden rounded border p-2 bg-white text-xs shadow;
  z-index: 1000;
}

.tooltip[data-show] {
  @apply block;
}

.tooltip__button {
  @apply flex items-center text-mixlr-red text-left;
}

.tooltip[data-popper-placement^='top']>.tooltip__arrow {
  bottom: -4px;
}

.tooltip[data-popper-placement^='bottom']>.tooltip__arrow {
  top: -4px;
}

.tooltip[data-popper-placement^='left']>.tooltip__arrow {
  right: -4px;
}

.tooltip[data-popper-placement^='right']>.tooltip__arrow {
  left: -4px;
}

.tooltip__arrow,
.tooltip__arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

.tooltip__arrow {
  visibility: hidden;
}

.tooltip__arrow::before {
  box-shadow: 2px 2px 2px rgba(0,0,0,.1);
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

.typed-cursor {
  display: inline;
}