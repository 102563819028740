.form__row {
  @apply
    max-w-lg
    mb-4
  ;
}

.form__row--checkbox {
  @apply
    flex
    gap-4
    mb-4
    mt-4
  ;

  label {
    @apply
      font-normal
      flex-1
    ;
  }

  input[type="checkbox"] {
    @apply mt-2;
  }
}

.form__label {
  @apply
    block
    font-bold
    mb-1
  ;
}

.form__input {
  @apply
    border
    border-solid
    border-gray-200
    mb-2
    py-3
    px-4
    rounded-md
    w-full
  ;
}

.form__input__textarea {
  @apply h-32;
}

.form__input__button {
  @apply
    relative
  ;

  > button {
    @apply
      absolute
      border-l
      border-gray-200
      p-0
      text-black
      flex
      h-[50px]
      items-center
      justify-center
      right-0
      rounded-none
      top-0
      w-[50px]
    ;
  }
}

.form__hint {
  @apply
    text-gray-400
    text-sm
  ;
}

.form__error {
  @apply 
    text-sm
    text-rose-500
  ;
}

.fieldWithErrors {
  .form__label {
    @apply text-rose-500
  }

  .form__input,
  + .form__input__button button {
    @apply border-rose-500
  }

  ~ .form__error.hidden {
    @apply block
  }
}

.form__select {
  @apply 
    border
    border-solid
    border-gray-200
    mb-2
    relative
    rounded-md 
    w-full
  ;

  select {
    @apply
      appearance-none
      bg-transparent
      border-none
      outline-none
      py-3
      px-4
      w-full
    ;
  }

  select::-ms-expand {
    display: none;
  }

  &:after {
    @apply
      absolute
      border-b
      border-r
      border-mixlr-red
      content-['']
      h-2
      -mt-[10px]
      pointer-events-none
      right-4
      translate-y-[50%] rotate-45
      top-[50%]
      w-2
    ;
  }

  &--rounded {
    @apply rounded-full;
  }
}

.details {
  @apply
    bg-gray-100
    max-w-lg
    mb-4
    py-3
    px-4
    rounded-md
  ;

  &__content {
    @apply mt-2;
  }
}

.delete-account {
  @apply
    bg-rose-100
    max-w-lg
    py-3
    px-4
    rounded-md
    text-rose-500
  ;

  > h3 {
    @apply
      font-bold
      text-lg
    ;
  }

  > p {
    @apply mb-4
  }
}

input[type="file"] {
  @apply
    mb-2
    text-sm
  ;
}