.pagination {
  @apply
    flex
    gap-4
    items-center
    justify-center
    mt-8
    w-full
  ;

  > * {
    @apply
      bg-gray-200
      flex
      h-12
      items-center
      justify-center
      not-italic
      rounded-full
      w-12
    ;
  }

  .current {
    @apply
      bg-mixlr-red
      text-white
    ;
  }
}