.detail-toggle {
  @apply
    text-sm
    relative
  ;

  &__summary {
    @apply cursor-pointer;
  }

  &__summary::-webkit-details-marker {
    @apply hidden;
  }

  &__summary::marker {
    @apply hidden;
  }

  &__dropdown {
    @apply
      absolute
      bg-white
      mt-3
      rounded-lg
      shadow
      shadow-gray-300
      right-0
      text-center
      w-72
      z-50
    ;
  }

  &__profile {
    @apply mx-auto;
  }

  &__logout {
    @apply
      block
      border-gray-200
      border-t
      mt-4
      px-4
      py-2
    ;
  }
}

.detail-toggle:not([open]) {
  .detail-toggle__dropdown {
    @apply hidden;
  }
}

.detail-toggle[open] {
  .detail-toggle__dropdown {
    @apply block;
  }
}