.action-button {
  @apply
    items-center
    h-[42px]
    flex
  ;

  @apply
    cursor-pointer
    relative
  ;

  &:after {
    @apply
      absolute
      content-['']
      h-4
      -bottom-4
      -left-4
      -right-4
    ;
  }

  ~ .action-button {
    @apply ml-4;
  }

  &__dropdown {
    @apply
      absolute
      bg-white
      invisible
      max-w-[220px]
      mt-2
      right-0
      rounded-lg
      top-full
      shadow
      shadow-slate-300
      z-50
    ;
  }

  &__list {
    @apply py-1;

    &--last {
      @apply
        border-t
        border-gray-200
      ;
    }
  }

  &__link {
    @apply
      items-center
      flex
      gap-2
      hover:text-mixlr-red
      py-2
      px-4
      whitespace-nowrap
    ;

    &--invert {
      @apply
        text-mixlr-red
        hover:text-black
      ;
    }
  }
}