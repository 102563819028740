.button {
  @apply inline-flex font-semibold py-2 px-4 rounded-full items-center justify-center;
}

.button--primary {
  @apply bg-mixlr-red hover:bg-mixlr-red-80 text-white;
}

.button--outline {
  @apply border-solid border border-mixlr-red text-mixlr-red hover:border-black hover:text-black py-2 px-4 rounded-full;
}

.button--outline-gray {
  @apply border-gray-200 hover:border-mixlr-red;
}

.button--outline-invert {
  @apply border-white text-white hover:bg-white hover:text-black;
}

.button--icon {
  @apply gap-2 pl-2;
}

.button--sm {
  @apply text-sm;
}

.button--lg {
  @apply lg:text-xl;
}

.button--xlg {
  @apply text-xl lg:text-2xl py-3 px-6;
}

.button--alert {
  @apply bg-rose-500 text-white;
}