.image {
  @apply
    items-center
    flex
    justify-center
    overflow-hidden
    rounded-full
  ;

  &--medium {
    @apply
      h-[58px]
      w-[58px]
    ;
  }

  &--thumb {
    @apply
      h-[36px]
      w-[36px]
    ;
  }

  &--thumb-small {
    @apply 
      h-[24px]
      w-[24px]
    ;
  }

  &--thumb-mini {
    @apply
      h-[16px]
      w-[16px]
    ;
  }

  &__img {
    @apply
      h-full
      object-cover
      w-full
  }
  
  &__svg {
    @apply
      bg-transparent
      fill-white
      h-[50%]
      w-[50%]
    ;

    g {
      @apply fill-white
    }
  }
}

.light-contrast {
  .image__svg {
    g {
      @apply fill-black
    }
  }
}